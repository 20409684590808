<template>
  <div class="menu-audit-logs">
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="Object.keys(pageData || {}).length">
        <div
          class="activity-item"
          v-for="(activity, index) in pageData"
          :key="index"
        >
          <el-tag class="date">
            {{ formatDate(activity.created_at) }}
          </el-tag>
          <div class="activities">
            <el-row type="flex" align="top">
              <el-col :span="3" class="time">
                <span class="font-sm text-grey-tertiary">
                  {{ formatTime(activity.created_at) }}
                </span>
              </el-col>
              <el-col :span="24" :class="'description'">
                <span class="font-sm">
                  {{ formatText(activity.description) }}
                </span>
              </el-col>
              <!-- <el-col :span="3" class="description">
                <router-link
                  :to="{
                    name: 'services.service-type.view',
                    params: { id: activity.menu_id, service: 'menu' },
                  }"
                  ><span class="font-sm action"> View Menu </span>
                </router-link>
              </el-col> -->
              <el-col :span="3">
                <el-dropdown class="description">
                  <span class="el-dropdown-link font-sm action">
                    View Combo
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(combo, index) in activity.combos"
                      :key="index"
                      >{{ combo }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <eden-content-empty v-else :text="'No audit logs'" :size="120" />
    </template>
  </div>
</template>

<script>
import menu from "@/requests/services/meal/menu";

export default {
  components: {},
  data() {
    return {
      loading: false,
      pageData: {},
      period: "",
      custom: {
        from: null,
        to: null,
      },
    };
  },
  mounted() {
    this.getMenuLogs({ period: "thisweek" });
  },
  methods: {
    getMenuLogs({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      this.loading = true;
      const params = `?start_date=${start_date}&end_date=${end_date}`;

      menu
        .getLogs(params)
        .then((res) => {
          const { status, data } = res.data;
          if (status) {
            this.pageData = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-audit-logs {
  .activity-item {
    margin-bottom: 32px;
    .date {
      margin-bottom: 24px;
    }

    .el-tag {
      background: var(--eden-blue-septenary);
      color: var(--eden-grey-primary);
      font-size: 0.75rem;
      font-weight: 500;
    }

    .activities {
      padding: 0 10px;

      .time {
        padding: 16px 0;
      }

      .description {
        padding: 16px 0;
        border-top: 1px solid #e2e9e6;

        .action {
          font-weight: 500;
          color: var(--eden-green-primary);
          text-decoration: underline;
        }
      }

      &:last-child {
        .description {
          border-bottom: 1px solid #e2e9e6;
        }
      }
    }
  }
}
</style>
