<template>
  <div>
    <eden-page-header
      :title="'Services'"
      :subtitle="`Menu`"
      section="Audit Log"
    >
      <template slot="actions">
        <div class="is-flex is-flex-wrap align-center">
          <eden-periods
            :default-period="'thisweek'"
            :custom-periods="periods"
            :show-custom-period="true"
            @set-period="getMenuLogs"
          />
        </div>
      </template>
    </eden-page-header>
    <menu-audit-log ref="logRef" />
  </div>
</template>

<script>
import MenuAuditLog from "@/components/Services/Meal/Menu/NewMenu/MenuAuditLog.vue";

export default {
  components: { MenuAuditLog },
  data: () => ({
    periods: {
      alltime: "All time",
      today: "Today",
      yesterday: "Yesterday",
      thisweek: "This week",
      lastweek: "Last week",
      thismonth: "This month",
    },
  }),
  methods: {
    getMenuLogs(period) {
      this.$refs.logRef?.getMenuLogs(period);
    },
  },
};
</script>
